import BaseActionGroup from '../BaseActionGroup';
import DeleteMessageAction from './messages/DeleteMessageAction';
import LoadMessagesAction from './messages/LoadMessagesAction';
import GetTotalMessagesCountAction from './messages/GetTotalMessagesCountAction';
import SendMessageAction from './messages/SendMessageAction';
import UpdateMessageAction from './messages/UpdateMessageAction';
import GetTotalThreadsCountAction from './messages/GetTotalThreadsCountAction';

class MessageActionGroup extends BaseActionGroup {
  sendMessage({ firebaseRoomId, firebaseThreadId, threadMessageId, message }) {
    const editMessage = message.editMessage;
    delete message.editMessage;

    return this.runInstance(SendMessageAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      threadMessageId: threadMessageId,
      message: message,
      editMessage: editMessage,
    });
  }

  updateMessage({ firebaseRoomId, firebaseThreadId, messageId, message }) {
    return this.runInstance(UpdateMessageAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      messageId: messageId,
      message: message,
    });
  }

  deleteMessage({ firebaseRoomId, firebaseThreadId, messageId, message }) {
    return this.runInstance(DeleteMessageAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      messageId: messageId,
      message: message,
    });
  }

  loadMessages({
    firebaseRoomId,
    firebaseThreadId,
    itemsPerPage = 50,
    lastPageFirstItem = null,
  }) {
    return this.runInstance(LoadMessagesAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      itemsPerPage: itemsPerPage,
      lastPageFirstItem: lastPageFirstItem,
    });
  }

  getTotalMessagesCount({ firebaseRoomId, firebaseThreadId }) {
    return this.runInstance(GetTotalMessagesCountAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getTotalThreadsCount({ firebaseRoomId, firebaseThreadId }) {
    return this.runInstance(GetTotalThreadsCountAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }
}

export default MessageActionGroup;
