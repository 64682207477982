import utils from '@happylife-a/utils';
import axios from 'axios';
import * as configureError from './interceptors/error';
import * as configureHeaders from './interceptors/headers';
import * as configureLogging from './interceptors/logger';
import * as configureRetry from './interceptors/retry';

export { default as BaseStatusError } from './errors/BaseStatusError';
export * as errors from './errors';

export const API_CORE = 'API.Core';
export const API_NOTIFICATION = 'API.Notification';
export const API_MESSAGING = 'API.Messaging';

export const apisNamed = {
  API_CORE: API_CORE,
  API_NOTIFICATION: API_NOTIFICATION,
  API_MESSAGING: API_MESSAGING,
  // ...
};

export const apis = Object.values(apisNamed);

export {
  addCommonHeader,
  removeCommonHeader,
  setClientApiConfigs,
} from './headers';

const interceptors = [
  configureHeaders,
  configureLogging,
  configureRetry,
  configureError,
];

async function mapInterceptors(method, httpClient, param) {
  for (const interceptor of interceptors) {
    const func = interceptor[method];
    if (typeof func === 'function') {
      await func(httpClient, param);
    }
  }

  return param;
}

const httpClients = {};

function buildAndConfigureApi(baseUrl) {
  const httpClient = axios.create({
    timeout: 50_000,
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });

  httpClient.interceptors.request.use(async (request) => {
    await mapInterceptors('onRequest', httpClient, request);
    return request;
  });

  httpClient.interceptors.response.use(
    async (response) => {
      await mapInterceptors('onSuccess', httpClient, response);
      return response;
    },
    async (error) => {
      await mapInterceptors('onError', httpClient, error);
      throw error;
    }
  );

  httpClient.buildUrl = (endpoint, params = {}) => {
    let finalUrl = baseUrl;
    if (finalUrl.endsWith('/')) {
      finalUrl = finalUrl.substring(finalUrl, 0, finalUrl.length - 1);
    }
    if (endpoint.startsWith('/')) {
      endpoint = endpoint.substring(1);
    }

    const queryParams = Object.entries(params)
      .reduce(
        (acc, [key, value]) => [...acc, `${key}=${encodeURIComponent(value)}`],
        []
      )
      .join('&');

    return `${finalUrl}/${endpoint}?${queryParams}`;
  };

  return httpClient;
}

export function configure(apisBaseUrlList) {
  for (const apiName of apis) {
    const baseUrl = apisBaseUrlList[apiName];
    utils.helpers.logging.info(
      `Configuring API ${apiName} with endpoint "${baseUrl}".`
    );

    httpClients[apiName] = buildAndConfigureApi(baseUrl);
  }
}

export async function mapOnClients(callback) {
  for (const [apiName, httpClient] of Object.entries(httpClients)) {
    await callback({ apiName: apiName, httpClient: httpClient });
  }
}

/**
 * @returns {import('axios').AxiosInstance}
 */
export default function getHttpClient(apiName) {
  if (!httpClients[apiName]) {
    throw new Error(
      `Make sure you have configured your http client: ${apiName}.`
    );
  }

  return httpClients[apiName];
}
