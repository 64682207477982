const commonHeaders = {};

export function addCommonHeader(key, value) {
  commonHeaders[key.toUpperCase()] = value;
}

export function removeCommonHeader(key, value) {
  commonHeaders[key.toUpperCase()] = value;
}

export function getCommonHeaders() {
  return commonHeaders;
}
