import firebase from '@happylife-a/firebase';

export default function MessagingFirebaseService(messagingType) {
  const connectToSocket = () => {
    firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRoomById({
        firebaseRoomId: 'non_existing',
      })
      .execute();
  };

  const createChatRoom = ({ firebaseRoomId, type, ...params }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .createChatRoom({
        firebaseRoomId: firebaseRoomId,
        type: type,
        ...params,
      })
      .execute();
  };

  const deleteChatRoom = ({ firebaseRoomId }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .deleteChatRoom({
        firebaseRoomId: firebaseRoomId,
      })
      .execute();
  };

  const addMemberToChatRoom = ({ firebaseRoomId, memberId }) => {
    return firebase.libraries.messaging[messagingType].process.members
      .addMemberToChatRoom({
        firebaseRoomId: firebaseRoomId,
        memberId: memberId,
      })
      .execute();
  };

  const removeMemberFromChatRoom = ({ firebaseRoomId, memberId }) => {
    return firebase.libraries.messaging[messagingType].process.members
      .removeMemberFromChatRoom({
        firebaseRoomId: firebaseRoomId,
        memberId: memberId,
      })
      .execute();
  };

  const loadChatRooms = ({ itemsPerPage, lastPageFirstItem = null }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRooms({
        itemsPerPage: itemsPerPage,
        lastPageFirstItem: lastPageFirstItem,
      })
      .execute();
  };

  const loadChatRoomById = ({ firebaseRoomId }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRoomById({
        firebaseRoomId: firebaseRoomId,
      })
      .execute();
  };

  const loadChatRoomMembers = ({ firebaseRoomId, memberTypes = null }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .loadChatRoomMembers({
        firebaseRoomId: firebaseRoomId,
        memberTypes: memberTypes,
      })
      .execute();
  };

  const loadMessages = ({
    firebaseRoomId,
    firebaseThreadId,
    itemsPerPage,
    lastPageFirstItem = null,
  }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .loadMessages({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        itemsPerPage: itemsPerPage,
        lastPageFirstItem: lastPageFirstItem,
      })
      .execute();
  };

  const getTotalMessagesCount = ({ firebaseRoomId, firebaseThreadId }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .getTotalMessagesCount({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      })
      .execute();
  };

  const getTotalThreadsCount = ({ firebaseRoomId, firebaseThreadId }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .getTotalThreadsCount({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      })
      .execute();
  };

  const sendMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    message,
  }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .sendMessage({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        threadMessageId: threadMessageId,
        message: message,
      })
      .execute();
  };

  const updateMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    messageId,
    message,
  }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .updateMessage({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        messageId: messageId,
        message: message,
      })
      .execute();
  };

  const deleteMessage = ({
    firebaseRoomId,
    firebaseThread,
    threadMessageId,
    messageId,
  }) => {
    return firebase.libraries.messaging[messagingType].process.messages
      .deleteMessage({
        firebaseRoomId: firebaseRoomId,
        firebaseThread: firebaseThread,
        threadMessageId: threadMessageId,
        messageId: messageId,
      })
      .execute();
  };

  const createThread = ({
    firebaseRoomId,
    firebaseThreadId,
    title,
    content,
    sender,
    chosenMedias,
  }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .createThread({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        title: title,
        content: content,
        sender: sender,
        chosenMedias: chosenMedias,
      })
      .execute();
  };

  const endChatThread = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
  }) => {
    return firebase.libraries.messaging[messagingType].process.rooms
      .endChatThread({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        threadMessageId: threadMessageId,
      })
      .execute();
  };

  const onChatThreadEnd = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    callback,
  }) =>
    firebase.libraries.messaging[messagingType].process.rooms
      .endChatThread({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        threadMessageId: threadMessageId,
      })
      .onChanged({
        callback: callback,
      });

  const onChatRoomAdded = ({ callback }) =>
    firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRooms({})
      .onChildAdded({
        callback: callback,
      });

  const onChatRoomChanged = ({ callback }) =>
    firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRooms({})
      .onChildChanged({
        callback: callback,
      });

  const onChatRoomRemoved = ({ callback }) =>
    firebase.libraries.messaging[messagingType].process.rooms
      .loadChatRooms({})
      .onChildRemoved({
        callback: callback,
      });

  const onMessageAdded = ({ firebaseRoomId, firebaseThreadId, callback }) =>
    firebase.libraries.messaging[messagingType].process.messages
      .loadMessages({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      })
      .onChildAdded({
        callback: callback,
      });

  const onMessageChanged = ({ firebaseRoomId, firebaseThreadId, callback }) =>
    firebase.libraries.messaging[messagingType].process.messages
      .loadMessages({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      })
      .onChildChanged({
        callback: callback,
      });

  const onMessageRemoved = ({ firebaseRoomId, firebaseThreadId, callback }) =>
    firebase.libraries.messaging[messagingType].process.messages
      .loadMessages({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      })
      .onChildRemoved({
        callback: callback,
      });

  return {
    connectToSocket: connectToSocket,
    createChatRoom: createChatRoom,
    deleteChatRoom: deleteChatRoom,
    addMemberToChatRoom: addMemberToChatRoom,
    removeMemberFromChatRoom: removeMemberFromChatRoom,
    loadChatRooms: loadChatRooms,
    loadChatRoomById: loadChatRoomById,
    loadChatRoomMembers: loadChatRoomMembers,
    loadMessages: loadMessages,
    getTotalMessagesCount: getTotalMessagesCount,
    getTotalThreadsCount: getTotalThreadsCount,
    sendMessage: sendMessage,
    updateMessage: updateMessage,
    deleteMessage: deleteMessage,
    endChatThread: endChatThread,
    onChatThreadEnd: onChatThreadEnd,
    createThread: createThread,
    onChatRoomAdded: onChatRoomAdded,
    onChatRoomChanged: onChatRoomChanged,
    onChatRoomRemoved: onChatRoomRemoved,
    onMessageAdded: onMessageAdded,
    onMessageChanged: onMessageChanged,
    onMessageRemoved: onMessageRemoved,
  };
}
