import '@happylife-a/polyfill';

import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  useQueries,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useQueryErrorResetBoundary,
  useIsFetching,
  useMutation,
  useIsMutating,
  useHydrate,
  useIsRestoring,
  hydrate,
  dehydrate,
} from '@tanstack/react-query';
import * as auth from './auth';
import * as analytics from './constants/analytics';
import * as patterns from './constants/patterns';
import * as socialLinks from './constants/socialLinks';
import * as constants from './core/constants';
import * as coreFactories from './core/factories';
import * as coreHooks from './core/hooks';
import * as coreHttp from './core/http';

// eslint-disable-next-line import/order
import * as contexts from './contexts';

import * as environment from './environment';
import * as helpers from './helpers';
import * as hooks from './hooks';
import * as providers from './providers';
import * as socket from './socket';
import * as versioning from './versioning';

const httpClient = {
  errors: {
    ...coreHttp.errors,
    BaseHttpError: coreHttp.BaseStatusError,
  },
  apis: coreHttp.apisNamed,
  configure: coreHttp.configure,
  setClientApiConfigs: coreHttp.setClientApiConfigs,
  addCommonHeader: coreHttp.addCommonHeader,
  removeCommonHeader: coreHttp.removeCommonHeader,
};

export {
  QueryClient,
  QueryClientProvider,
  Hydrate,
  useQueries,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useQueryErrorResetBoundary,
  useIsFetching,
  useMutation,
  useIsMutating,
  useHydrate,
  useIsRestoring,
  hydrate,
  dehydrate,
  constants,
  coreHooks,
  coreFactories,
  helpers,
  contexts,
  providers,
  hooks,
  auth,
  httpClient,
  patterns,
  analytics,
  socialLinks,
  environment,
  versioning,
  socket,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  QueryClient: QueryClient,
  QueryClientProvider: QueryClientProvider,
  Hydrate: Hydrate,
  useQueries: useQueries,
  useQuery: useQuery,
  useInfiniteQuery: useInfiniteQuery,
  useQueryClient: useQueryClient,
  useQueryErrorResetBoundary: useQueryErrorResetBoundary,
  useIsFetching: useIsFetching,
  useMutation: useMutation,
  useIsMutating: useIsMutating,
  useHydrate: useHydrate,
  useIsRestoring: useIsRestoring,
  hydrate: hydrate,
  dehydrate: dehydrate,
  constants: constants,
  coreHooks: coreHooks,
  coreFactories: coreFactories,
  helpers: helpers,
  contexts: contexts,
  providers: providers,
  hooks: hooks,
  auth: auth,
  httpClient: httpClient,
  patterns: patterns,
  analytics: analytics,
  socialLinks: socialLinks,
  environment: environment,
  versioning: versioning,
  socket: socket,
};
