import React from 'react';
import webApp from '@happylife-a/web-app';
import { Flex, Box } from '@chakra-ui/react';
import DailyProducts from '../Products/DailyProducts';
import SectionTitle from '../SectionTitle';
import * as constants from '../../../constants';

export default function Content({
  onClickSeeAll,
  onSelectProduct,
  selectedProducts,
  isMobile,
  search,
}) {
  return (
    <webApp.components.ScrollableBox
      as={Flex}
      flexDir="column"
      maxW="full"
      h={400}
      overflowY="scroll"
      overflowX="hidden"
      gap={2}
    >
      <Box>
        <SectionTitle
          onViewAllClick={() => onClickSeeAll(constants.FAVORITES_PRODUCTS)}
          search={search}
        />
        <DailyProducts
          onSelectProduct={onSelectProduct}
          selectedProducts={selectedProducts}
          isMobile={isMobile}
          useInfiniteScroll={false}
          search={search}
        />
      </Box>
    </webApp.components.ScrollableBox>
  );
}
